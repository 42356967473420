/* eslint-disable max-len */
/* --------------------------------------- */
/* Server Side Configuration
/* --------------------------------------- */
// put all sensitive configuration that is used in server side only here
// this way to avoid sensitive values such as ecnryption key to be included in js bundling and can be exposed in client side.

/* Encryption */
const encryption = {
    key: 'TXAjwm8k53PJG9NacLbyZavvQB2qBh43',
    algorithm: 'aes-256-cbc',
};

/* key server fcm */
const fcm = {
    topic: 'newnotificationspwa',
    // mitra10
    // FCM_KEY_SERVER: 'AAAArC4L43I:APA91bHO_GDA3kgaV1ht7OhIpvOu20Vn8jbfx6-3SLIdLc3U_5gO2GHmLBUITBqQc01lrX38zmTKkDjhfUMK1JWcOQcuul_8NwDQHmL3b1KzxzfvVyvbPm5hmVSkCQsC1QEy6Ym9vDft',
    // FCM_KEY_SERVER: 'AAAASFtEbAs:APA91bFguiC_0iH3RahE1K458xboQMUDVfcXgfrRhub9SOs8zj7HeC2LexMXn7k6kRT_07_pSJXg9QZE8on6e9YB2mLOVX42_4BSylDACBHSTwPzVNh8HRyUpRVBqGKJRqzCVDpjdapP',

    // Mitra10-Firebase
    FCM_KEY_SERVER: 'AAAArC4L43I:APA91bG3gg8N_u8i9JQwofOAOs6-AdYNCNlQPamVCRPZDqr0cqfT-XxpYEqd5l2nmvczAvmb2xFy3QNeWrfui_GLlzDeok1_fcSiRBj1YCiJVrSN0CdhM4buelau6LXm24F2mxWnn3WD',
};

/* Cookie */
const SESSION_SECRET = 'asdasdd1212ads12!!!@**DADxx1';

module.exports = {
    encryption,
    SESSION_SECRET,
    fcm,
};
